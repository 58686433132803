import React from "react";
import {Link} from 'react-scroll';

function Home() {
    // render()
    {
        return (

            // <ul style={{display: 'flex', listStyle: 'none', justifyContent: 'space-around'}}>
            //     <li><Link activeClass="active" to="Home" spy={true} smooth={true}>Home</Link>

            <div className="fontsample">
                <div class="container">
                    <div class="row align-items-center my-5">
                        <div class="col-lg-7">
                            {/*<img*/}
                            {/*    class="img-fluid rounded mb-4 mb-lg-0"*/}
                            {/*    src="http://placehold.it/900x400"*/}
                            {/*    alt=""*/}
                            {/*/>*/}
                        </div>
                        <div class="col-lg-5">
                            <h1 class="font-weight-light">Home</h1>
                            <p className="fontsample"> Lorem Ipsum is simply
                                dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's
                                standard dummy text
                                ever since the 1500s, when an unknown printer
                                took a galley of
                                type and scrambled it to make a type specimen
                                book.
                                Lorem Ipsum is simply
                                dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's
                                standard dummy text
                                ever since the 1500s, when an unknown printer
                                took a galley of
                                type and scrambled it to make a type specimen
                                book.
                                Lorem Ipsum is simply
                                dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's
                                standard dummy text
                                ever since the 1500s, when an unknown printer
                                took a galley of
                                type and scrambled it to make a type specimen
                                book.
                                Lorem Ipsum is simply
                                dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's
                                standard dummy text
                                ever since the 1500s, when an unknown printer
                                took a galley of
                                type and scrambled it to make a type specimen
                                book.
                                Lorem Ipsum is simply
                                dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's
                                standard dummy text
                                ever since the 1500s, when an unknown printer
                                took a galley of
                                type and scrambled it to make a type specimen
                                book.
                                Lorem Ipsum is simply
                                dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's
                                standard dummy text
                                ever since the 1500s, when an unknown printer
                                took a galley of
                                type and scrambled it to make a type specimen
                                book.
                                Lorem Ipsum is simply
                                dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's
                                standard dummy text
                                ever since the 1500s, when an unknown printer
                                took a galley of
                                type and scrambled it to make a type specimen
                                book.
                                Lorem Ipsum is simply
                                dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's
                                standard dummy text
                                ever since the 1500s, when an unknown printer
                                took a galley of
                                type and scrambled it to make a type specimen
                                book.
                                Lorem Ipsum is simply
                                dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's
                                standard dummy text
                                ever since the 1500s, when an unknown printer
                                took a galley of
                                type and scrambled it to make a type specimen
                                book.
                                Lorem Ipsum is simply
                                dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's
                                standard dummy text
                                ever since the 1500s, when an unknown printer
                                took a galley of
                                type and scrambled it to make a type specimen
                                book.
                                Lorem Ipsum is simply
                                dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's
                                standard dummy text
                                ever since the 1500s, when an unknown printer
                                took a galley of
                                type and scrambled it to make a type specimen
                                book.
                                Lorem Ipsum is simply
                                dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's
                                standard dummy text
                                ever since the 1500s, when an unknown printer
                                took a galley of
                                type and scrambled it to make a type specimen
                                book.
                                Lorem Ipsum is simply
                                dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's
                                standard dummy text
                                ever since the 1500s, when an unknown printer
                                took a galley of
                                type and scrambled it to make a type specimen
                                book. 55555
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            //     </li>
            // </ul>
        );
    }
    }


export default Home;